/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function($) {

	// equalizer firefox bug
	function reinit_equalizer(){
		Foundation.reInit('equalizer');
	}
	window.onload=reinit_equalizer();

	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// allow second tap on mobile/tablet menu to open parent url
	jQuery('.menu-item-has-children > a').click(function(){
	    jQuery(this).unbind('click');
	});

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	socialToggle();

	// sends GA event when submitting Contact Us form
	if ( document.getElementById( 'gform_2' ) ) {
		jQuery(document).on( 'gform_confirmation_loaded', function( e ){
			sendEvent( petaGA_FormCategory_Feedback, petaGA_FormAction_Submitted, 'Animal Rahat Contact Form' );
		} );
	}

});

function socialToggle() {
	jQuery('.social-wrap a').click( function(e) {
		e.preventDefault();

		var shareMethod = jQuery( this ).data( 'social' );
		if ( shareMethod == 'facebook' ) {
			loadFacebookShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'redirect' ) );
		} else if ( shareMethod == 'twitter' ) {
			loadTwitterShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'text' ) );
		
	    } else if ( shareMethod == 'email' ) {
			loadEmailShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'subject' ), jQuery( this ).data( 'body' ) );
		}
	});
}

function loadFacebookShare( url, redirect, title, location_label ) {
	if (typeof(location_label)==='undefined') location_label = 'Share';
	var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
	var URL = "https://www.facebook.com/dialog/share?" +
	"app_id=259182790944924" +
	"&display=popup" +
	"&href=" + url +
	"&redirect_uri=" + redirect;
	sendSocialEvent(petaGA_SNsource_Facebook, location_label, url);
	return window.open(URL, "_blank", strWindowFeatures);
}

function loadTwitterShare( url, text ) {
	var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
	var URL = "https://twitter.com/intent/tweet?" +
		"&text=" + text +
		"&url=" + url;
	sendSocialEvent(petaGA_SNsource_Twitter, 'Share', url);
	return window.open(URL, "_blank", strWindowFeatures);
}

function loadEmailShare( url, subject, body ) {
	sendSocialEvent('Email', petaGA_GenericAction_Share, url );
	window.location.href = 'mailto:?subject=' + subject + '&body=' + body;
}
