// switches page banner img with mobile img
jQuery(document).ready(function($) {
    var banner = document.querySelector( '.page-banner.has-image' );

    if ( banner ) {
        var desktopImg = banner.style.background;
        var mobileImg = banner.dataset.mobileImg;

        // check if mobileImg exists
        if ( mobileImg !== undefined ) {
            if ( window.innerWidth < 768 ) {
                banner.style.background = 'url(' + mobileImg + ') 100% 20%/cover no-repeat';
            }

            window.addEventListener( 'resize', function() {
                if ( window.innerWidth < 768 ) {
                    banner.style.background = 'url(' + mobileImg + ') 100% 20%/cover no-repeat';
                } else {
                    banner.style.background = desktopImg;
                }
            });
        }

    }

});
