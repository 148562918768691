/*
Custom JS for homepage and social sharing
Not minified with other JS, enqueued separately
*/

jQuery(document).ready(function($) {

	// set to active if autocompleted
	if( $('#supporter-emailaddress input').val() ) {
		$('#enews-signup').addClass('active').animate();
	}

	// show/hide privacy policy when using enews sign up
	$('#enews-signup').click(function() {
		$(this).addClass('active').animate();
	});

	$('#enews-signup').delegate(':input', 'focus', function() {
		$(this).parents('.enews').addClass('active').animate();
	});

	$(".off-canvas-wrapper").mouseup(function(e){
		var enews_div = $("#enews-signup");
		if (e.target.id != enews_div.attr('id') && !enews_div.has(e.target).length) {
			enews_div.removeClass('active');
		}
	});

	$('.pma-subscription-widget #pma-submit').click(function() {
		$(this).parents('.enews').removeClass('active');
	});

	// button style radio buttons
	$('.grid-promo .amounts label').click(function(){
		$('.grid-promo .amounts label').removeClass('active');
		$('.grid-promo .other-amount').removeClass('active');
		$(this).addClass('active');
	});

	$('.grid-promo .other-amount').click(function(){
		$('.grid-promo .amounts label').removeClass('active');
		$(this).addClass('active');
	});

	// default amount = 35
	$('.grid-promo .amounts label[for="DonateAmount_35"]').addClass('active');
	$('#DonateAmount_25').prop('checked', false);
	$('#DonateAmount_35').prop('checked');

	var home_panel_width = $('.panel-container');
	$('.panel-container .wrap').width(home_panel_width.outerWidth());
	$( window ).resize(function() {
		var home_panel_width = $('.panel-container');
		$('.panel-container .wrap').width(home_panel_width.outerWidth());
	});

	var home_event = ('ontouchstart' in window) ? 'click' : 'mouseenter mouseleave';
	$('.panel-container .panel').on(home_event, function () {
		$(this).toggleClass('active');
	});

	if ( Foundation.MediaQuery.is('small only') ) {
		promosRearrange();
	}

	$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		if ( newSize == 'small' ) {
			promosRearrange();
		} else if ( oldSize == 'small' ) {
			jQuery('#grid-item-6').after( jQuery('#promo-vsk') );
			jQuery('#grid-item-10').after( jQuery('#grid-promo-0') );
			jQuery('#grid-item-12').after( jQuery('#grid-promo-1') );

			jQuery('#grid-item-18').after( jQuery('#grid-promo-2') );
			jQuery('#grid-item-20').after( jQuery('#grid-promo-3') );
			jQuery('#grid-item-26').after( jQuery('#grid-promo-4') );
		}

		// if ( newSize == 'large' && oldSize == 'medium' ) {
		// 	$('#offcanvas').foundation('close');
		// }
	});


}); /* end of as page load scripts */

function promosRearrange() {
	jQuery('#mobile-promo-top').append( jQuery('#promo-vsk') );
	jQuery('#mobile-promo-top').append( jQuery('#grid-promo-0') );
	jQuery('#mobile-promo-top').append( jQuery('#grid-promo-1') );

	jQuery('#mobile-promo-bottom').append( jQuery('#grid-promo-2') );
	jQuery('#mobile-promo-bottom').append( jQuery('#grid-promo-3') );
	jQuery('#mobile-promo-bottom').append( jQuery('#grid-promo-4') );
}

// Load more posts on homepage
jQuery(function($){
	var offset = 8;
	var total = $('.load-more-posts').data('total');
	var placement = $('.load-more-posts').data('placement');
	var promo_num = $('.load-more-posts').data('promo-num');
	var promo_total = $('.load-more-posts').data('promo-total');
	var loading = true;
	var $content = $('#more-entries');
	var load_more_posts = function(){
		var data = {
			action: 'peta_home_load_more',
			offset: offset,
			total: total,
			placement: placement,
			promo_num: promo_num
		};
		$.ajax({
			type       : "GET",
			data       : data,
			dataType   : "html",
			url        : home_load_more.ajaxurl,
			beforeSend : function(){
				$('.load-more-posts').addClass('loading');
			},
			success    : function(data){
				if ( offset >= total ) {
					$('.load-more-posts').remove();
				}
				if ( data.length ){
					$content.append(data);

					new_div = $( '.more-posts-block' ).last();
					new Foundation.Equalizer( new_div ).applyHeight();

					if ( Foundation.MediaQuery.atLeast('medium') ) {
						socialToggle();
					}
					$('.load-more-posts').removeClass('loading');
				}
			},
			error     : function(jqXHR, textStatus, errorThrown) {
				alert(jqXHR + " :: " + textStatus + " :: " + errorThrown);
			}
		});
	}

	$('.load-more-posts').click(function(event) {
		sendEvent(petaGA_LinkCategory_Link, petaGA_GenericAction_ClickThrough, 'Homepage More Posts Button');
		event.preventDefault();
		load_more_posts();
		offset += 8;
		promo_num = promo_increment(promo_num, promo_total);
	});

	// should increment promo number by 2 on every load more click - account for loop
	function promo_increment( promo_num, promo_total ) {
		difference = promo_total - promo_num;

		if ( 2 == difference ) {
			promo_num = 0;
		} else if ( 1 == difference ) {
			promo_num = 1;
		} else {
			promo_num += 2;
		}
		return promo_num;
	}

});
