// mobile search toggle
jQuery(document).ready(function($) {

	var btn = document.getElementById( 'js-search-toggle' );
	var search = document.querySelector( '.search-header' );
	var input = document.querySelector( '.search-header input[type=text]' );

	if ( btn ) {
		btn.addEventListener( 'click', function() {
			if ( this.classList.contains( 'active' ) ) {
				clear();
			} else {
				this.classList.add( 'active' );
				search.classList.add( 'show' );
				input.focus();
			}
		});

		// remove .show class if going from mobile to tablet
		window.addEventListener( 'resize', function() {
			if ( window.innerWidth >= 768 ) {
				clear();
			}
		});

		function clear() {
			search.classList.remove( 'show' );
			btn.classList.remove( 'active' );
			input.value = '';
		}
	}

});
