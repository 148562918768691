// dynamic repositioning of banner header angle-background image
jQuery(document).ready(function($) {
	var overlay = null;
	var isHomepage = document.body.classList.contains( 'page-template-front-page' );

	if ( isHomepage ) {
		overlay = document.querySelectorAll( '.orbit-slide > .overlay' );
	} else {
		overlay =  document.getElementById( 'maroon-overlay' );
	}

	if ( overlay !== null ) {
		if ( window.innerWidth >= 1200 ) {
			set_background();
		}

		window.addEventListener('resize',function() {
			if ( window.innerWidth >= 1200 ) {
				set_background();
			}
		});
	}

	function set_background() {
		var content = undefined;
		var spacerWidth = undefined;

		if ( isHomepage ) {
			content = document.querySelector( '.orbit-caption > .row' );
			spacerWidth = 490 + 20;
		} else {
			content = document.querySelector( '.page-banner > .row' );
			spacerWidth = 490 + 45;
		}

		var pageWidth = window.innerWidth;
		var leftWidth = spacerWidth;
		if ( content && 'offsetLeft' in content && content.offsetLeft ) {
			leftWidth += content.offsetLeft;
		}

		// what % of page width should be taken up by maroon background
		var leftPercentage = ( ( pageWidth - leftWidth )  / pageWidth ) * 100 ;

		if ( isHomepage ) {
			for( var x = 0; x < overlay.length; x++ ) {
				overlay[x].style.background = 'linear-gradient(-80deg, rgba( 0, 0, 0, 0.3 ) ' + leftPercentage + '%, rgba( 80, 0, 37, 0.8 ) ' + ( leftPercentage + 0.1 ) + '%)';
			}

		} else {
			overlay.style.background = 'linear-gradient(-80deg, rgba( 0, 0, 0, 0.3 ) ' + leftPercentage + '%, rgba( 80, 0, 37, 0.8 ) ' + ( leftPercentage + 0.1 ) + '%)';
		}
	}

});
